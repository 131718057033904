import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Hero, Columns, Container } from 'react-bulma-components'

function LeeQuote() {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          <hr />
          <Columns className="is-inline-flex is-vcentered is-centered">
            <Columns.Column className="is-2 is-offset-1">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579120408-lee.jpg?fm=png&w=500&fit=facearea&facepad=5&mask=ellipse"
                alt="Quote By Lee - Pocketworks"
              />
            </Columns.Column>
            <Columns.Column className="is-6 ml-5">
              <p className="is-size-4">
                "It's easy to say it, but our true goal is to create long-term
                win-win partnerships with our clients. We want everyone around
                the table to be motivated to get the best results possible."
              </p>
              <p className="tag is-medium">
                Lee, Head of Customer Partnerships
              </p>
            </Columns.Column>
          </Columns>
          <hr />
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default LeeQuote
