/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import ServicesMobileAppDevelopment from 'routes/services/mobile-app-development'

// Meta Component
import Meta from 'components/Meta/Meta'

// ServicesMobileAppDevelopment Route
export default ServicesMobileAppDevelopment

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query ServicesMobileAppDevelopmentQuery {
    page: datoCmsPage(slug: { eq: "services/mobile-app-development" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      content
      featuredMedia {
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { fm: "jpg", auto: "compress", maxW: 600, bg: "#FAF7F2" }
        )
      }
    }
    testimonials: allDatoCmsClientTestimonial(limit: 2) {
      edges {
        node {
          id
          clientName
          clientRole
          content
          clientCompany
          isFlipped
          caseStudy {
            slug
          }
          clientLogo {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "jpg", auto: "compress" }
            )
          }
          clientImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "jpg", auto: "compress" }
            )
          }
        }
      }
    }
  }
`
