import PropTypes from 'prop-types'
import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'
import Testimonial from 'components/testimonials'
import Clients from 'components/clients'
import Customers from 'components/customers'
import Objectives from 'components/objectives'
import LeeQuote from 'components/leeQuote'

// Page Components
import AppDevHero from './components/app-dev-hero'
import Stats from './components/stats'
import AppServices from './components/app-services'

function ServicesMobileAppDevelopment({ data: { page, testimonials } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <AppDevHero page={page} />
      <Stats />
      <AppServices />
      <Testimonial testimonials={testimonials} />
      <Clients />
      <Customers />
      <Objectives />
      <LeeQuote />
    </Layout>
  )
}

ServicesMobileAppDevelopment.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServicesMobileAppDevelopment
