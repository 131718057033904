import React from 'react'
import { Box, Section, Container, Columns } from 'react-bulma-components'
// Components
import ServicesBlock from 'components/services-block'

function AppServices() {
  return (
    <>
      <Section className="is-medium has-background-grey-lighter">
        <Container className="content">
          <h2 className="title has-text-centereda">Why choose Pocketworks?</h2>
          <Columns>
            <Columns.Column className="is-7">
              <p className="is-size-4">
                Our clients see mobile apps as key revenue generators in their
                business. They are busy people, and need a long-term partner
                that can help them deliver end-to-end products without the
                stressful management overhead.
              </p>
              <h3>We design for growth from day one</h3>
              <p>
                The average app loses 73% of new users within the first 3 days.
                Our focus on growth helps your app perform better. More
                downloads, more paying customers, and higher customer lifetime
                value.
              </p>
              <h3>We put your customers first with user centered design</h3>
              <p>
                99% of apps fail, usually becuase they don't resonate with
                customers or they run out of budget. We help you mitigate these
                risks by learning from your audience and letting customer
                insight guide your app design.
              </p>
              <h3>You'll have one supplier to build your entire platform</h3>
              <p>
                App development is pretty straight forward. What's hard is
                desiging an integrated experience that truly engages people and
                keeps them coming back. To do this, our team offers:
              </p>
              <ul>
                <li>Mobile strategy and planning</li>
                <li>Paid and organic user acquisition</li>
                <li>User research to validate ideas</li>
                <li>Conversion optimisation</li>
                <li>iOS, Android, and cross-platform Flutter Development</li>
                <li>Backend, cloud, data and API development</li>
                <li>AI, GenAI and data-driven solutions</li>
                <li>Automated and manual app testing</li>
                <li>Analytics, data cleaning and dashboards</li>
              </ul>
              <h3>
                You can sleep at night knowing you have a dependable, long-term
                partner{' '}
              </h3>
              <p>
                We still work with our first client from back in 2012, and have
                helped them grow from zero to three million app customers. Some
                of our clients are global groups with over £1bn revenue. We've
                been stable and profitable for years.
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <ServicesBlock
        title={<span>Work with a UK App Developers</span>}
        subtitle="Build best-in-class experience using native iOS and Android apps, or accellerate your time-to-market with Flutter or PWAs."
        items={[
          {
            title: 'iOS Apps',
            text: 'Create robust, high-quality apps that run on iPads or iPhones to reach 50.4% of your UK smartphone audience.',
          },
          {
            title: 'Android Apps',
            text: 'Reach 49.6% of the UK smartphone users by developing apps for Samsung, Sony and Google devices.',
          },
          {
            title: 'Flutter Apps',
            text: "Reduce time-to-market and cost by creating apps that run on Android and iOS using Google's slick new framework.",
            url: '/services/flutter-app-development',
          },
          {
            title: 'Progressive Web Apps',
            text: 'Give users a fast and efficient mobile web experience without the barrier of downloading an app.',
          },
          {
            title: 'Push Notifications',
            text: 'Achieve 60% open rates and keep customers up-to-date with progress, alerts and offers.',
          },
          {
            title: 'In App Messaging',
            text: 'Make customers aware of important service changes, promotions or facts. Right within your app.',
          },
          {
            title: 'Location and Mapping',
            text: 'Show the most relevant data based on where customers are. Useful for product search, place lookups and marketing.',
          },
          {
            title: 'Beacons',
            text: 'Influence customer behaviour when they are near your premises or improve your in-store experience.',
          },
          {
            title: 'Biometrics',
            text: 'Add extra security of your app without compromising convenience. Perfect for employee or customer facing apps.',
          },
          {
            title: 'Security Testing',
            text: 'Have your app PEN tested by our security partners to ensure your sensitive data is protected from cyber threats.',
          },
          {
            title: 'IoT Connectivity',
            text: 'Give customers control and visibility of your smart devices and instruments using Bluetooth or WiFi Connect.',
          },
          {
            title: 'Integrated with APIs',
            text: 'Connect your apps to CRM, CMS, ERP or your bespoke back-office systems. Store data offline for mobile working.',
          },
        ]}
      />
    </>
  )
}

export default AppServices
