import PropTypes from 'prop-types'
import React from 'react'
import { Container, Hero } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ProductJsonLd } from 'gatsby-plugin-next-seo'

function AppDevHero({ page }) {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          <div className="columns is-tablet is-vcentered">
            <div className="column is-7">
              <h1 className="subtitle is-size-5 is-uppercase mb-2 pb-0">
                UK App Development Service
              </h1>
              <h2 className="title is-size-2 has-text-weight-semibold mt-0 is-landing-text">
                Develop an app that creates impact and growth.
              </h2>
              <ProductJsonLd
                name="UK App Development Service"
                description="App develpment service is for UK businesses looking to launch and grow a mobile app or platforms. Our engagements typically run for 1+ years, including everything you need to develop your native or cross-platform app."
                brand="Pocketworks"
                offers={{
                  '@type': 'Offer',
                  price: 200000.0,
                  priceCurrency: 'GBP',
                }}
              />
              <p className="is-size-4 is-offset-1">
                For organisations needing a long-term app development partner
                for their mission-critical apps and digital platforms.
              </p>
            </div>
            <div className="column is-4">
              <GatsbyImage
                image={page.featuredMedia.gatsbyImageData}
                alt="Mobile App Development - Pocketworks"
              />
            </div>
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
AppDevHero.propTypes = {
  page: PropTypes.object,
}
export default AppDevHero
